<template>
	<div id="balances-container">
		<h3>{{ languageStrings.casinoBalance }}</h3>
		<button
			v-if="
				(systemSettings?.features?.allowBarcodeAccountAccess || systemSettings?.features?.allowVerbalCodeAccountAccess) &&
				playerState.isUserVerified &&
				systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
				countryCode !== 'MX'
			"
			@click="requestOpenQRCodeModal()"
			class="btn"
		>
			{{ languageStrings.getQRcode }}
		</button>
		<table class="balances">
			<tr class="header-row">
				<th>Casino</th>
				<th>Regular</th>
				<th>{{ languageStrings.promo }}</th>
				<th>{{ languageStrings.lcokedFunds }}</th>
				<th>{{ languageStrings.lockFundsCount }}</th>
				<th
					v-if="
						(systemSettings?.features?.allowBarcodeAccountAccess || systemSettings?.features?.allowVerbalCodeAccountAccess) &&
						playerState.isUserVerified &&
						!systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
						countryCode !== 'MX'
					"
				>
					{{ languageStrings.codeAvailable }}
				</th>
			</tr>
			<tr class="data-row" v-for="(item, index) in casinosForCodeGeneration" :key="index">
				<td>{{ item.name }}</td>
				<td>{{ item.currencyTool.formatFromAU(item.balance.regularAU, item.currencyTool.displayType.full) }}</td>
				<td>{{ item.currencyTool.formatFromAU(item.balance.promoAU, item.currencyTool.displayType.full) }}</td>
				<td>{{ item.currencyTool.formatFromAU(item.lockedFundsAU, item.currencyTool.displayType.full) }}</td>
				<td>{{ item.lockedClaimsCount }}</td>
				<td
					v-if="
						(systemSettings?.features?.allowBarcodeAccountAccess || systemSettings?.features?.allowVerbalCodeAccountAccess) &&
						playerState.isUserVerified &&
						!systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
						countryCode !== 'MX'
					"
				>
					<button @click="requestOpenQRCodeModal(item.id)" :title="languageStrings.getQRcode" class="btn">{{ languageStrings.getQRcode }}</button>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	name: "HomeTable",
	props: {
		systemSettings: Object,
		casinosForCodeGeneration: Array,
		playerState: Object,
		languageStrings: Object,
		casinoId: Number,
	},
	methods: {
		requestOpenQRCodeModal(casinoId = this.casinoId) {
			this.eventBus.emit("requestQRModal", casinoId);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

#balances-container {
	display: grid;
	margin: auto;
	width: 80%;
	background-color: #d7d7d7;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	box-sizing: border-box;
}

.balances {
	position: relative;
	width: 100%;
	margin: auto;
	border-collapse: collapse;
	text-align: center;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
}

.balances tr {
	transition: background-color 0.3s;
}

tr.header-row {
	position: sticky;
	top: 0;
	background-color: #000;
	color: #fff;
	border-bottom: 1px solid rgb(123, 123, 123);
	white-space: break-spaces;
}

.balances tr.data-row:hover,
.balances tr:nth-child(2n):not(.open):hover {
	background-color: #fff;
	color: #000;
}

.balances tr:nth-child(2n):not(.open) {
	background-color: #bbb;
}
</style>
