<template>
	<div
		id="captcha"
		class="cf-turnstile"
		v-if="serverRules?.captchaType === 'Turnstile'"
		:data-sitekey="serverRules?.captchaSiteKey"
	></div>
	<div v-else data-comment="No Captcha Type Specified"></div>
	<div id="timer">
		{{ languageStrings.redirect }} {{ timer >= 60 ? Math.ceil(timer / 60) : timer }} {{ timerText }}
	</div>
</template>

<script>
import router from "@/router";
import { onBeforeUnmount } from "vue";

export default {
	name: "Captcha",
	inheritAttrs: false,
	props: {
		serverRules: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			captchaId: null,
			captchaExpireTime: 290000,
			// captchaExpireTime: 2900, // for testing
			captchaExpireDate: new Date(),
			captchaWidgetSize: this.isMobile ? "compact" : "normal",
			captchaType: this.serverRules.captchaType,
			timer: 0,
			timerText: "",
			timerInterval: null,
		};
	},
	created() {
		this.eventBus.on("initializeCaptcha", () => {
			if (this.serverRules.captchaType === "Turnstile" && !this.captchaId) this.turnstileSetup();
		});
		onBeforeUnmount(() => {
			this.cleanupComponent();
			this.eventBus.off("initializeCaptcha");
		});
	},
	mounted() {
		this.captchaExpireDate = new Date(new Date().getTime() + this.captchaExpireTime);
	},
	methods: {
		cleanupComponent() {
			clearInterval(this.timerInterval);
			turnstile.remove(this.captchaId);
			this.captchaId = null;
		},
		async turnstileSetup() {
			this.captchaId = await turnstile.render("#captcha", {
				theme: "dark",
				size: this.captchaWidgetSize,
				sitekey: this.serverRules.captchaSiteKey,
				callback: (token) => {
					this.eventBus.emit("captchaSuccess", token);
				},
			});
			// Waiting till render is complete before starting reset interval
			if (this.captchaId) this.turnstileTimeout();
		},
		renderTimer() {
			let min = 1000;
			this.timer = Math.ceil((this.captchaExpireDate.getTime() - new Date().getTime()) / min);
			let minute = this.timer > 60 ? this.languageStrings.minutes[0] : this.languageStrings.minutes[1];
			this.timerText = this.timer < 60 ? this.languageStrings.seconds : minute;
			if (this.timer <= 0) {
				this.cleanupComponent();
				this.eventBus.emit("captchaDismissed");
				if (
					this.$route.name === "RegisterNewUser" ||
					this.$route.name === "MultiComponentRegisterView" ||
					this.$route.name === "ExtendedRegisterView"
				) {
					router.push("/");
				}
			}
		},
		turnstileTimeout() {
			// A Turnstile token is valid for 300 seconds, after that it will be rejected by siteverify.
			// Setting interval to 290 seconds to allow some wiggle room for potential latency.
			// At some point in the future we may need to have this configurable, if and when we add more captcha providers.
			this.renderTimer();
			this.timerInterval = setInterval(() => {
				this.renderTimer();
			}, 1000);
		},
	},
};
</script>

<style scoped>
#timer {
	margin-top: 10px;
	text-align: center;
}
</style>
